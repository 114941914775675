import { useNavigate } from 'react-router-dom';
import { useDeleteAccStore } from '../../app/store/deleteAccStore';
import LoadingButton from '@mui/lab/LoadingButton';
import { TitleBlock } from '../../components/UI/TitleBlock';
import { CustomAlert } from '../../components/UI/CustomAlert';
import { Button } from '@mui/material';
import cls from './DeleteAccPage.module.scss';

export const Step2 = () => {
  const [isLoading, step1, errorMessage] = useDeleteAccStore((state) => [
    state.isLoading,
    state.step1,
    state.errorMessage
  ]);
  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    step1();
  };

  return (
    <>
      <TitleBlock>Удаление данных и аккаунта</TitleBlock>
      <div className={cls.container}>
        <p>
          Вы действительно хотите удалить все данные вашего аккаунта PAYOUT?
        </p>
      </div>
      {errorMessage && <CustomAlert>{errorMessage}</CustomAlert>}
      <div className={cls.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => navigate(-1)}
        >
          Отмена
        </Button>
        <LoadingButton
          variant="outlined"
          color="secondary"
          onClick={handleDeleteAccount}
          loading={isLoading}
        >
          <span>Продолжить</span>
        </LoadingButton>
      </div>
    </>
  );
};
