import React, { ReactNode } from 'react';
import { IconButton, Modal } from '@mui/material';
import { ReactComponent as IconClose } from '../../../assets/icons/iconCloseModal.svg';
import cls from './CustomModal.module.scss';

interface Props {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const CustomModal = ({ children, open, onClose }: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
      }}
    >
      <div className={cls.container}>
        <IconButton
          color="white"
          onClick={onClose}
          aria-label="закрыть"
          size="small"
        >
          <IconClose fontSize="small" />
        </IconButton>

        {children}
      </div>
    </Modal>
  );
};
