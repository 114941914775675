import axios from 'axios';
import { useUserStore } from '../store';

const API_URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000';

export const apiInstance = axios.create({
  baseURL: API_URL
});

apiInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('token');

  if (accessToken) {
    config.headers.Token = accessToken;
  }
  return config;
});

apiInstance.interceptors.response.use(
  (response) => response, // Directly return successful responses.
  async (error) => {
    if (error.response.status === 401) {
      useUserStore.getState().deleteUser();
    }

    return Promise.reject(error); // For all other errors, return the error as is.
  }
);
