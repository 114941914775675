import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { jwtDecode } from 'jwt-decode';
import { PayoutJwtType, UserRoles } from '../models/userRoles';
import { getUserRole } from '../helpers/getUserRole';

type State = {
  user: string;
  userRole: UserRoles;
  uuid: string;
};

type Actions = {
  addUser: (phone: string, token: string) => void;
  deleteUser: () => void;
};

const initialState: State = {
  user: '',
  userRole: UserRoles.USER,
  uuid: ''
};

export const useUserStore = create<State & Actions>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        addUser: async (phone, token) => {
          localStorage.setItem('token', token);

          const decoded = jwtDecode<PayoutJwtType>(token);
          const role = getUserRole(Number(decoded.groups));
          set({
            user: phone,
            userRole: role,
            uuid: decoded.user_id
          });

          window.location.href = window.location.origin;
          //await router.navigate(Paths.APP);
        },
        deleteUser: () => {
          set(initialState);
          localStorage.removeItem('token');
        }
      }),
      { name: 'user' }
    ),
    { name: 'userStore' }
  )
);
