import { AxiosResponse } from 'axios';
import { apiInstance } from '../api';
import {
  ISignUpStep1,
  ISignUpStep2,
  ISignUpStep3
} from '../models/response/signUpResponse';

export default class RecoveryService {
  static async step1(
    phone: string,
    code_country: string
  ): Promise<AxiosResponse<ISignUpStep1>> {
    return apiInstance.post('/auth/recovery/pincreate', {
      phone,
      code_country
    });
  }

  static async step2(
    code: string,
    secret: string
  ): Promise<AxiosResponse<ISignUpStep2>> {
    return apiInstance.post('/auth/recovery/pinenter', { code, secret });
  }

  static async step3(
    token: string,
    password: string,
    password_confirm: string
  ): Promise<AxiosResponse<ISignUpStep3>> {
    return apiInstance.post('/auth/recovery', {
      token,
      password,
      password_confirm
    });
  }
}
