import React from 'react';
import cls from './UserBlock.module.scss';
import avatar from '../../assets/images/avatarDemo.png';
import { useUserStore } from '../../app/store';

export const UserBlock = () => {
  const user = useUserStore((state) => state.user);

  return (
    <div className={cls.container}>
      {/*<img src={avatar} alt="avarat" />*/}
      <h4 className={cls.userPhone}>{user}</h4>
    </div>
  );
};
