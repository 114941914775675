import React, { useState } from 'react';
import { FormTitle } from '../../components/UI/FormTitle';
import { Form } from '../../components/UI/Form';
import { InputPhoneNum } from '../../components/UI/InputPhoneNum';
import { InputPassword } from '../../components/UI/InputPassword';
import { Alert, Button } from '@mui/material';
import { CustomCheckbox } from '../../components/UI/CustomCheckbox';
import LoadingButton from '@mui/lab/LoadingButton';
import { CustomModal } from '../../components/UI/CustomModal';
import { ModalForgotPass } from '../../components/ModalForgotPass';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/configs/routePaths';
import { useSignInStore } from '../../app/store';
import { FormBottomBlock } from '../../components/UI/FormBottomBlock';

export const Step1 = () => {
  const [step1, errorMessage, isLoading] = useSignInStore((state) => [
    state.step1,
    state.errorMessage,
    state.isLoading
  ]);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  const [checked, setChecked] = React.useState(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    step1(phone, password);
  };

  return (
    <>
      <FormTitle title={'Добро пожаловать'} text={'Войдите в свой аккаунт'} />
      <Form onSubmit={handleSubmit}>
        <InputPhoneNum
          phone={phone}
          error={error}
          setPhone={setPhone}
          setError={setError}
        />
        <InputPassword
          label={'Введите пароль'}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <CustomCheckbox checked={checked} onChange={handleChangeCheckbox}>
          Запомнить меня
        </CustomCheckbox>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isLoading}
          disabled={error}
        >
          <span>Войти</span>
        </LoadingButton>
      </Form>
      <FormBottomBlock>
        <Button variant="outlined" color="secondary" onClick={handleOpen}>
          Не помню пароль
        </Button>
        <CustomModal open={open} onClose={handleClose}>
          <ModalForgotPass handleClose={handleClose} />
        </CustomModal>
        <div>или</div>
        <Button
          color="secondary"
          variant="outlined"
          component={Link}
          to={Paths.SIGN_UP}
        >
          Зарегистрироваться
        </Button>
      </FormBottomBlock>
    </>
  );
};
