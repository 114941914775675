import { createBrowserRouter } from 'react-router-dom';
import { Paths } from '../../configs/routePaths';
import { App } from '../../App';
import {
  DeletedPage,
  ErrorPage,
  MainPage,
  ResetPassStep1,
  ResetPassStep2,
  ResetPassStep3,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  SignUpStep1,
  SignUpStep2,
  SignUpStep3
} from '../../../pages';
import { switchUserRoutes } from './roleRoutes';
import { SignInStep1, SignInStep2 } from '../../../pages/SignInPage';

export const router = createBrowserRouter([
  {
    path: Paths.APP,
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            path: Paths.APP,
            element: <MainPage />,
            errorElement: <ErrorPage />,
            children: switchUserRoutes()
          },
          {
            path: Paths.SIGN_IN,
            element: <SignInPage />,
            children: [
              {
                path: Paths.SIGN_IN,
                element: <SignInStep1 />
              },
              {
                path: Paths.SIGN_IN_STEP_2,
                element: <SignInStep2 />
              }
            ]
          },
          {
            path: Paths.SIGN_UP,
            element: <SignUpPage />,
            children: [
              {
                path: Paths.SIGN_UP,
                element: <SignUpStep1 />
              },
              {
                path: Paths.SIGN_UP_STEP_2,
                element: <SignUpStep2 />
              },
              {
                path: Paths.SIGN_UP_STEP_3,
                element: <SignUpStep3 />
              }
            ]
          },
          {
            path: Paths.RESET_PASSWORD,
            element: <ResetPasswordPage />,
            children: [
              {
                path: Paths.RESET_PASSWORD,
                element: <ResetPassStep1 />
              },
              {
                path: Paths.RESET_PASSWORD_STEP_2,
                element: <ResetPassStep2 />
              },
              {
                path: Paths.RESET_PASSWORD_STEP_3,
                element: <ResetPassStep3 />
              }
            ]
          },
          {
            path: Paths.DELETED,
            element: <DeletedPage />
          }
        ]
      }
    ]
  }
]);
