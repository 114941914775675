import React from 'react';
import { useChangePasswordStore } from '../../app/store/changePasswordStore';
import { TitleBlock } from '../../components/UI/TitleBlock';
import { FormPasswordConfirm } from '../../components/FormPasswordConfirm';
import cls from './ChangePasswordPage.module.scss';

export const Step3 = () => {
  const [isLoading, step3, errorMessage] = useChangePasswordStore((state) => [
    state.isLoading,
    state.step3,
    state.errorMessage
  ]);

  return (
    <>
      <TitleBlock>Придумайте новый пароль</TitleBlock>
      <div className={cls.form}>
        <FormPasswordConfirm
          submitAction={step3}
          errorMessage={errorMessage}
          isLoading={isLoading}
          buttonText={'Подтвердить'}
          bottomButtons={false}
        />
      </div>
    </>
  );
};
