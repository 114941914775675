import React from 'react';
import { useSignUpStore } from '../../app/store';
import { FormTitle } from '../../components/UI/FormTitle';
import { FormPhoneNumber } from '../../components/FormPhoneNumber';

export const Step1 = () => {
  const { step1, errorMessage, isLoading } = useSignUpStore();

  return (
    <>
      <FormTitle
        title={'Добро пожаловать'}
        text={'Войдите или зарегистрируйтесь'}
      />
      <FormPhoneNumber
        action={step1}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </>
  );
};
