import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { router } from '../providers/Router/AppRouter';
import { Paths } from '../configs/routePaths';
import { useUserStore } from './userStore';
import axios from 'axios';
import ChangePasswordService from '../services/changePasswordService';

type State = {
  errorMessage: string;
  phoneNumber: string;
  secret: string;
  time_new_pin: string;
  timer: boolean;
  token: string;
  uuid: string;
  isLoading: boolean;
};

type Actions = {
  step1: () => void;
  step2: (code: string) => void;
  step3: (password: string, password2: string) => void;
  resendSmsCode: () => void;
  resetStore: () => void;
  setTimer: (value: boolean) => void;
};

const initialState: State = {
  errorMessage: '',
  phoneNumber: '',
  secret: '',
  time_new_pin: '',
  timer: false,
  token: '',
  uuid: '',
  isLoading: false
};

const uuid = useUserStore.getState().uuid;

export const useChangePasswordStore = create<State & Actions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      resetStore: () => set(initialState),
      step1: async () => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await ChangePasswordService.step1(uuid);

          set({
            secret: data.secret,
            time_new_pin: data.time_new_pin,
            timer: true
          });

          await router.navigate(Paths.SMS_CODE);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            set({ errorMessage: error.response?.data });
          } else {
            console.log(error);
          }
        } finally {
          set({ isLoading: false });
        }
      },
      step2: async (code) => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await ChangePasswordService.step2(
            uuid,
            code,
            get().secret
          );

          set({ token: data.token });

          await router.navigate(
            `/${Paths.SETTINGS}/${Paths.CHANGE_PASSWORD}/${Paths.PASSWORD}`
          );
        } catch (error) {
          if (axios.isAxiosError(error)) {
            set({ errorMessage: error.response?.data });
          } else {
            console.log(error);
          }
        } finally {
          set({ isLoading: false });
        }
      },
      step3: async (password, password2) => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await ChangePasswordService.step3(
            uuid,
            get().token,
            password,
            password2
          );

          await router.navigate(
            `/${Paths.SETTINGS}/${Paths.CHANGE_PASSWORD}/${Paths.DONE}`
          );
        } catch (error) {
          if (axios.isAxiosError(error)) {
            set({ errorMessage: error.response?.data });
          } else {
            console.log(error);
          }
        } finally {
          set({ isLoading: false });
        }
      },
      resendSmsCode: async () => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await ChangePasswordService.step1(uuid);

          set({
            secret: data.secret,
            time_new_pin: data.time_new_pin,
            timer: true
          });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            set({ errorMessage: error.response?.data });
          } else {
            console.log(error);
          }
        } finally {
          set({ isLoading: false });
        }
      },
      setTimer: (value) => {
        set({ timer: value });
      }
    }),
    { name: 'recoveryPasswordStore' }
  )
);
