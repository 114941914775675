import React from 'react';
import { TitleBlock } from '../../components/UI/TitleBlock';

export const Step4 = () => {
  return (
    <>
      <TitleBlock>Ваш пароль успешно изменен!</TitleBlock>
    </>
  );
};
