import React from 'react';
import { useSignUpStore } from '../../app/store';
import { FormTitle } from '../../components/UI/FormTitle';
import { FormPasswordConfirm } from '../../components/FormPasswordConfirm';

export const Step3 = () => {
  const [step3, errorMessage, isLoading] = useSignUpStore((state) => [
    state.step3,
    state.errorMessage,
    state.isLoading
  ]);

  return (
    <>
      <FormTitle
        title={'Установите пароль'}
        text={'Для входа в систему придумайте пароль.'}
      />
      <FormPasswordConfirm
        submitAction={step3}
        isLoading={isLoading}
        errorMessage={errorMessage}
        buttonText="Зарегистрироваться"
      />
    </>
  );
};
