import React, { useEffect, useState } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import cls from './PasswordValidation.module.scss';

const IconValidSwitch = ({ state }: { state: boolean }) => {
  return (
    <>
      {state ? (
        <CheckCircleRoundedIcon color="success" fontSize="small" />
      ) : (
        <CancelRoundedIcon color="warning" fontSize="small" />
      )}
    </>
  );
};

interface Props {
  password: string;
  setError: (value: boolean) => void;
}

export const PasswordValidation = ({ password, setError }: Props) => {
  const [minCharacters, setMinCharacters] = useState(false);
  const [upperLetters, setUpperLetters] = useState(false);
  const [lowerLetters, setLowerLetters] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [symbols, setSymbols] = useState(false);

  useEffect(() => {
    password.length >= 8 ? setMinCharacters(true) : setMinCharacters(false);
    password.match(/[A-Z]/g) ? setUpperLetters(true) : setUpperLetters(false);
    password.match(/[a-z]/g) ? setLowerLetters(true) : setLowerLetters(false);
    password.match(/[0-9]/g) ? setNumbers(true) : setNumbers(false);
    password.match(/\W|_/g) ? setSymbols(false) : setSymbols(true);
  }, [password]);

  useEffect(() => {
    minCharacters && numbers && upperLetters && lowerLetters && symbols
      ? setError(false)
      : setError(true);
  }, [minCharacters, numbers, upperLetters, lowerLetters, symbols]);

  return (
    <div className={cls.container}>
      <div className={cls.validLine}>
        <IconValidSwitch state={minCharacters} />
        <p>Не менее 8 символов</p>
      </div>
      <div className={cls.validLine}>
        <IconValidSwitch state={upperLetters} />
        <p>Заглавные буквы</p>
      </div>
      <div className={cls.validLine}>
        <IconValidSwitch state={lowerLetters} />
        <p>Строчные буквы</p>
      </div>
      <div className={cls.validLine}>
        <IconValidSwitch state={numbers} />
        <p>Цифры</p>
      </div>
      <div className={cls.validLine}>
        <IconValidSwitch state={symbols} />
        <p>Не должен содержать спецсимволы</p>
      </div>
    </div>
  );
};

interface Props2 {
  password: string;
  password2: string;
  error: boolean;
  setError: (value: boolean) => void;
}

export const PasswordValidation2 = ({
  password,
  password2,
  error,
  setError
}: Props2) => {
  useEffect(() => {
    password2 === password ? setError(false) : setError(true);
  }, [setError, password, password2]);

  return (
    <div className={cls.container}>
      <div className={cls.validLine}>
        <IconValidSwitch state={!error} />
        <p>Пароли совпадают</p>
      </div>
    </div>
  );
};
