import React, { ReactNode } from 'react';
import cls from './Form.module.scss';

interface FormProps {
  children: ReactNode;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const Form = ({ children, ...props }: FormProps) => {
  return (
    <form className={cls.Form} {...props}>
      {children}
    </form>
  );
};
