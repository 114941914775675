import React from 'react';
import cls from './FormTitle.module.scss';

interface Props {
  title: string;
  text: string;
  textAlign?: 'center' | 'start' | 'left';
}

export const FormTitle = ({ title, text, textAlign }: Props) => {
  return (
    <div
      className={cls.FormTitle}
      style={textAlign ? { textAlign: textAlign } : { textAlign: 'center' }}
    >
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  );
};
