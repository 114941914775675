import React from 'react';
import { Link } from 'react-router-dom';
import { useUserStore } from '../../app/store';
import { Paths } from '../../app/configs/routePaths';
import { UserRoles } from '../../app/models/userRoles';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import { AccountMenu } from '../AccountMenu/AccountMenu';
import { CustomSwitcher } from '../UI/CustomSwitcher/CustomSwitcher';
import { UserBlock } from '../UserBlock';
import { ReactComponent as LogoSvg } from '../../assets/icons/logoPayout.svg';
import cls from './Header.module.scss';

export const Header = () => {
  const [user, userRole] = useUserStore((state) => [
    state.user,
    state.userRole
  ]);

  return (
    <header className={cls.header}>
      <WidthLimiterPadding>
        <div className={cls.container}>
          <Link to={Paths.APP} className={cls.logo}>
            <LogoSvg />
            {userRole !== UserRoles.USER && <span>{userRole}</span>}
          </Link>
          {user && (
            <div className={cls.nav}>
              {userRole !== UserRoles.ADMIN && (
                <CustomSwitcher
                  label={'PRO'}
                  checked={userRole === UserRoles.PRO}
                />
              )}
              <UserBlock />
              <AccountMenu />
            </div>
          )}
        </div>
      </WidthLimiterPadding>
    </header>
  );
};
