import React from 'react';
import { useChangePasswordStore } from '../../app/store/changePasswordStore';
import LoadingButton from '@mui/lab/LoadingButton';
import { TitleBlock } from '../../components/UI/TitleBlock';
import { CustomAlert } from '../../components/UI/CustomAlert';
import cls from './ChangePasswordPage.module.scss';

export const Step1 = () => {
  const [isLoading, step1, errorMessage] = useChangePasswordStore((state) => [
    state.isLoading,
    state.step1,
    state.errorMessage
  ]);

  const handleChangePass = () => {
    step1();
  };

  return (
    <>
      <TitleBlock>Изменение пароля</TitleBlock>
      <div className={cls.container}>
        <p>Для смены пароля подтвердите, что это ваш аккаунт.</p>
        <p>Получите проверочный код.</p>
      </div>
      {errorMessage && <CustomAlert>{errorMessage}</CustomAlert>}
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleChangePass}
        loading={isLoading}
      >
        <span>Получить код</span>
      </LoadingButton>
    </>
  );
};
