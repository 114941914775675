import React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

export const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 24,
  padding: 0,
  margin: '0 0 0 8px !important',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#161514',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#161514',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 20
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#B9B7B7',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

interface Props {
  label?: string;
  checked: boolean;
  setChecked?: (value: boolean) => void;
}

export const CustomSwitcher = ({ label, checked, setChecked }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked?.(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <CustomSwitch sx={{ m: 1 }} checked={checked} onChange={handleChange} />
      }
      label={label}
      labelPlacement="start"
      sx={{
        margin: 0,
        '& .MuiFormControlLabel-label': {
          fontWeight: 500
        }
      }}
    />
  );
};
