import React from 'react';
import { useTimer } from 'react-timer-hook';

export const MyTimer = ({
  expiryTimestamp,
  onExpire
}: {
  expiryTimestamp: string;
  onExpire: () => void;
}) => {
  const newTimerTime = new Date(expiryTimestamp);
  const { seconds, minutes } = useTimer({
    expiryTimestamp: newTimerTime,
    onExpire: () => onExpire()
  });

  return (
    <span style={{ textAlign: 'center' }}>
      <span>{minutes}</span>:
      <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
    </span>
  );
};
