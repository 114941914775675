import { Alert } from '@mui/material';
import React, { ReactNode } from 'react';

export const AlertInfo = ({ children }: { children: ReactNode }) => {
  return (
    <Alert severity="info" variant="filled" sx={{ width: 'fit-content' }}>
      {children}
    </Alert>
  );
};
