import { parsePhoneNumberFromString } from 'libphonenumber-js';

//helper для форматирования телефона с пробелом -> "+7 8005553535"
export const formatPhoneForReq = (phone: string) => {
  const parsePhone = parsePhoneNumberFromString(phone);

  if (parsePhone?.countryCallingCode) {
    return (
      '+' + parsePhone?.countryCallingCode + ' ' + parsePhone?.nationalNumber
    );
  } else {
    return phone;
  }
};
