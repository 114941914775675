import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  required?: boolean;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CustomCheckbox = ({
  children,
  required = false,
  checked,
  onChange
}: Props) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox required={required} checked={checked} onChange={onChange} />
        }
        label={children}
      />
    </FormGroup>
  );
};
