import React from 'react';
import { useSignUpStore } from '../../app/store';
import { FormTitle } from '../../components/UI/FormTitle';
import { FormSmsCode } from '../../components/FormSmsCode';

export const Step2 = () => {
  const [
    phoneNumber,
    resendSmsCode,
    step2,
    errorMessage,
    isLoading,
    time_new_pin,
    timer,
    setTimer
  ] = useSignUpStore((state) => [
    state.phoneNumber,
    state.resendSmsCode,
    state.step2,
    state.errorMessage,
    state.isLoading,
    state.time_new_pin,
    state.timer,
    state.setTimer
  ]);

  const resendSmsAction = () => {
    resendSmsCode();
  };

  const stopTimer = () => {
    setTimer(false);
  };

  return (
    <>
      <FormTitle
        title={'Введите код из SMS'}
        text={`Код отправлен на номер ${phoneNumber}`}
      />
      <FormSmsCode
        submitAction={step2}
        errorMessage={errorMessage}
        isLoading={isLoading}
        resendSmsAction={resendSmsAction}
        timeNewPin={time_new_pin}
        timer={timer}
        stopTimer={stopTimer}
      />
    </>
  );
};
