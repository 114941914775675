import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  TextField
} from '@mui/material';
import { IMaskInput } from 'react-imask';
import { normalizePhoneNumber } from '../../../app/helpers/normalizePhoneNumber';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ReactCountryFlag from 'react-country-flag';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+0 000 000 00 00"
        definitions={{
          '#': /[1-9]/
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

interface Props {
  phone: string;
  setPhone: (value: string) => void;
  setError: (value: boolean) => void;
  error?: boolean;
}

export const InputPhoneNumNewTest = (props: Props) => {
  const { phone, setPhone, setError, error } = props;
  const [country, setCountry] = useState('');

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const onBlurPhone = () => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.isValid() ? setError(false) : setError(true);
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.country ? setCountry(phoneNumber?.country) : setCountry('');
  }, [phone]);

  return (
    <FormControl variant="outlined" style={{ gap: '8px' }}>
      <label htmlFor="phone">Введите свой номер телефона</label>
      <OutlinedInput
        startAdornment={
          country ? (
            <InputAdornment position="end">
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{ width: '20px' }}
              />
            </InputAdornment>
          ) : (
            ''
          )
        }
        value={phone}
        onChange={onChangePhone}
        onBlur={onBlurPhone}
        id="phone"
        name="phone"
        type="tel"
        error={error}
        inputComponent={TextMaskCustom as any}
      />
      {error && <FormHelperText>Некорректный номер</FormHelperText>}
    </FormControl>
  );
};

export const InputPhoneNum = (props: Props) => {
  const { phone, setPhone, setError, error } = props;
  const [country, setCountry] = useState('');

  const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length < 2) {
      setPhone('+');
    } else {
      setPhone(normalizePhoneNumber(e.target.value));
    }
  };

  const onBlurPhone = () => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.isValid() ? setError(false) : setError(true);
  };

  useEffect(() => {
    const phoneNumber = parsePhoneNumberFromString(phone);
    phoneNumber?.country ? setCountry(phoneNumber?.country) : setCountry('');
  }, [phone]);

  return (
    <FormControl variant="outlined" style={{ gap: '8px' }}>
      <label htmlFor="phone">Введите свой номер телефона</label>
      <TextField
        required
        id="phone"
        name="phone"
        type="tel"
        placeholder="+"
        value={phone}
        onChange={onChangePhone}
        onBlur={onBlurPhone}
        error={error}
        helperText={error ? 'Некорректный номер' : ''}
        InputProps={{
          startAdornment: country && (
            <InputAdornment position="end">
              <ReactCountryFlag
                countryCode={country}
                svg
                style={{ width: '20px' }}
              />
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
};
