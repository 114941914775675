import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useAppStore, useUserStore } from '../../app/store';
import { Paths } from '../../app/configs/routePaths';
import { CustomSwitcher } from '../UI/CustomSwitcher/CustomSwitcher';
import { CustomModal } from '../UI/CustomModal';
import { ModalLogout } from '../ModalLogout';
import { UserBlock } from '../UserBlock';
import { ReactComponent as BurgerMenu } from '../../assets/icons/menu.svg';
import { ReactComponent as BurgerMenuClose } from '../../assets/icons/menu-close.svg';
import { Button } from '@mui/material';
import { UserRoles } from '../../app/models/userRoles';
import cls from './AccountMenu.module.scss';

export const AccountMenu = () => {
  const [user, userRole] = useUserStore((state) => [
    state.user,
    state.userRole
  ]);
  const isMobileWidth = useAppStore((state) => state.isMobileWidth);
  const [openMenu, setOpenMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const refMenu = useRef(null);

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  const logoutUser = () => {
    setShowModal(true);
    handleCloseMenu();
  };

  useOnClickOutside(refMenu, handleCloseMenu);

  return (
    <div className={cls.container}>
      <button
        className={cls.burgerBtn}
        onClick={() => setOpenMenu((prevState) => !prevState)}
      >
        {openMenu ? <BurgerMenuClose /> : <BurgerMenu />}
      </button>
      <nav
        ref={refMenu}
        className={cls.menu}
        style={openMenu ? { display: 'flex' } : {}}
      >
        <ul className={cls.list}>
          {isMobileWidth && (
            <>
              <li>
                <UserBlock />
              </li>
              {userRole !== UserRoles.ADMIN && (
                <li>
                  <CustomSwitcher
                    label={'PRO'}
                    checked={userRole === UserRoles.PRO}
                  />
                </li>
              )}
            </>
          )}
          <li>
            <Button
              variant="text"
              color="secondary"
              fullWidth
              component={Link}
              to={Paths.SETTINGS}
              onClick={handleCloseMenu}
            >
              Настройки
            </Button>
          </li>
          <li>
            <Button
              variant="text"
              color="secondary"
              fullWidth
              onClick={logoutUser}
            >
              Выйти
            </Button>
            <CustomModal open={showModal} onClose={() => setShowModal(false)}>
              <ModalLogout handleClose={() => setShowModal(false)} />
            </CustomModal>
          </li>
        </ul>
      </nav>
    </div>
  );
};
