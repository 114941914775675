import { useChangePasswordStore } from '../../app/store/changePasswordStore';
import { TitleBlock } from '../../components/UI/TitleBlock';
import { FormSmsCode } from '../../components/FormSmsCode';
import cls from './ChangePasswordPage.module.scss';

export const Step2 = () => {
  const [
    isLoading,
    resendSmsCode,
    step2,
    errorMessage,
    time_new_pin,
    timer,
    setTimer
  ] = useChangePasswordStore((state) => [
    state.isLoading,
    state.resendSmsCode,
    state.step2,
    state.errorMessage,
    state.time_new_pin,
    state.timer,
    state.setTimer
  ]);

  const resendSmsAction = () => {
    resendSmsCode();
  };

  const stopTimer = () => {
    setTimer(false);
  };

  return (
    <>
      <TitleBlock>Изменение пароля</TitleBlock>
      <div className={cls.container}>
        <p>Введите код из SMS. Код отправлен на ваш телефонный номер</p>
      </div>
      <div className={cls.form}>
        <FormSmsCode
          submitAction={step2}
          errorMessage={errorMessage}
          isLoading={isLoading}
          resendSmsAction={resendSmsAction}
          timeNewPin={time_new_pin}
          timer={timer}
          stopTimer={stopTimer}
        />
      </div>
    </>
  );
};
