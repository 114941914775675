import { Link } from 'react-router-dom';
import { Paths } from '../../app/configs/routePaths';
import { Button } from '@mui/material';
import { TitleBlock } from '../../components/UI/TitleBlock';
import cls from './DeleteAccPage.module.scss';

export const Step1 = () => {
  return (
    <>
      <TitleBlock>Удаление данных и аккаунта</TitleBlock>
      <div className={cls.container}>
        <p>
          Удаление аккаунта означает, что вы не сможете вернуть историю
          транзакций.
        </p>
        <b>Все данные вашего аккаунта будут удалены.</b>
        <p>
          Если вы готовы навсегда удалить аккаунт, нажмите на кнопку “Удалить
          аккаунт”.
        </p>
      </div>
      <Button
        color="secondary"
        component={Link}
        to={Paths.DELETE_ACCOUNT_CONFIRM}
      >
        <span>Удалить аккаунт</span>
      </Button>
    </>
  );
};
