import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import SignInService from '../services/SignInService';
import axios from 'axios';
import { formatPhoneForReq } from '../helpers/formatPhoneForReq';
import { useUserStore } from './userStore';
import { router } from '../providers/Router/AppRouter';
import { Paths } from '../configs/routePaths';

type State = {
  phoneNumber: string;
  password: string;
  secret: string;
  time_new_pin: string;
  timer: boolean;
  errorMessage: string;
  isLoading: boolean;
};

type Actions = {
  addPhone: (value: string) => void;
  resetStore: () => void;
  step1: (phone: string, password: string) => void;
  step2: (code: string) => void;
  resendSmsCode: () => void;
  setTimer: (value: boolean) => void;
};

const initialState: State = {
  phoneNumber: '',
  password: '',
  secret: '',
  time_new_pin: '',
  timer: false,
  errorMessage: '',
  isLoading: false
};

const addUser = useUserStore.getState().addUser;

export const useSignInStore = create<State & Actions>()(
  devtools(
    (set, get) => ({
      ...initialState,
      resetStore: () => set(initialState),
      addPhone: (value) => set({ phoneNumber: value }),
      step1: async (phone, password) => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await SignInService.step1(
            formatPhoneForReq(phone),
            password
          );

          set({
            phoneNumber: phone,
            secret: data.secret,
            time_new_pin: data.time_new_pin,
            password: password,
            timer: true
          });

          await router.navigate(Paths.SIGN_IN_STEP_2);
        } catch (e) {
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        } finally {
          set({ isLoading: false });
        }
      },
      step2: async (code) => {
        set({ errorMessage: '', isLoading: true });

        try {
          const { data } = await SignInService.step2(code, get().secret);

          addUser(get().phoneNumber, data.token);
        } catch (e) {
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        } finally {
          set({ isLoading: false });
        }
      },
      resendSmsCode: async () => {
        set({ errorMessage: '', isLoading: true });
        const { phoneNumber, password } = get();

        try {
          const { data } = await SignInService.step1(
            formatPhoneForReq(phoneNumber),
            password
          );

          set({
            secret: data.secret,
            time_new_pin: data.time_new_pin,
            timer: true
          });
        } catch (e) {
          if (axios.isAxiosError(e)) {
            set({ errorMessage: e.response?.data });
          } else {
            console.log(e);
            set({ errorMessage: 'Произлошла ошибка. Попробуйте позже.' });
          }
        } finally {
          set({ isLoading: false });
        }
      },
      setTimer: (value) => {
        set({ timer: value });
      }
    }),
    { name: 'signInStore' }
  )
);
