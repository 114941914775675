import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/configs/routePaths';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputPassword } from '../UI/InputPassword';
import { PasswordValidation, PasswordValidation2 } from '../PasswordValidation';
import { Alert, Button } from '@mui/material';
import { Form } from '../UI/Form';
import { FormBottomBlock } from '../UI/FormBottomBlock';

interface Props {
  submitAction: (pass1: string, pass2: string) => void;
  errorMessage: string;
  isLoading: boolean;
  buttonText: string;
  bottomButtons?: boolean;
}

export const FormPasswordConfirm = ({
  submitAction,
  errorMessage,
  isLoading,
  buttonText,
  bottomButtons = true
}: Props) => {
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  const handleChangePass1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleChangePass2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(e.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (error1 || error2) {
      return;
    }

    submitAction(password, password2);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputPassword
        value={password}
        onChange={handleChangePass1}
        label={'Пароль'}
        error={error1}
        helperText={
          <PasswordValidation password={password} setError={setError1} />
        }
      />
      <InputPassword
        value={password2}
        onChange={handleChangePass2}
        label={'Подтверждение пароля'}
        error={error2}
        helperText={
          <PasswordValidation2
            password={password}
            password2={password2}
            error={error2}
            setError={setError2}
          />
        }
      />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <LoadingButton variant="contained" loading={isLoading} type="submit">
        <span>{buttonText}</span>
      </LoadingButton>
      {bottomButtons && (
        <FormBottomBlock>
          <div>или</div>
          <Button
            color="secondary"
            variant="outlined"
            component={Link}
            to={Paths.SIGN_IN}
          >
            Войдите
          </Button>
        </FormBottomBlock>
      )}
    </Form>
  );
};
