import React, { FC, ReactNode } from 'react';
import cls from './TitleBlock.module.scss';

interface ITitleTitle {
  children: ReactNode;
}

export const TitleBlock: FC<ITitleTitle> = ({ children }) => {
  return <h2 className={cls.TitleBlock}>{children}</h2>;
};
