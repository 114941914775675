import { Alert } from '@mui/material';
import { ReactNode } from 'react';
import cls from './CustomAlert.module.scss';

export const CustomAlert = ({ children }: { children: ReactNode }) => {
  return (
    <Alert severity="error">
      <div className={cls.text}>
        <h4>Ошибка:</h4>
        {children}
      </div>
    </Alert>
  );
};
