import { apiInstance } from '../api';
import { E164Number } from 'libphonenumber-js/types';
import { AxiosResponse } from 'axios';
import {
  ISignUpStep1,
  ISignUpStep2,
  ISignUpStep3
} from '../models/response/signUpResponse';

export default class SignUpService {
  static async step1(
    phone: E164Number,
    code_country: string
  ): Promise<AxiosResponse<ISignUpStep1>> {
    return apiInstance.post('/auth/register/pincreate', {
      phone,
      code_country
    });
  }

  static async step2(
    code: string,
    secret: string
  ): Promise<AxiosResponse<ISignUpStep2>> {
    return apiInstance.post('/auth/register/pinenter', { code, secret });
  }

  static async step3(
    token: string,
    password: string,
    password_confirm: string
  ): Promise<AxiosResponse<ISignUpStep3>> {
    return apiInstance.post('/auth/register', {
      token,
      password,
      password_confirm
    });
  }
}
