import { apiInstance } from '../api';
import { AxiosResponse } from 'axios';
import { ISignInStep1, ISignInStep2 } from '../models/response/signInResponse';

export default class SignInService {
  static async step1(
    phone: string,
    password: string
  ): Promise<AxiosResponse<ISignInStep1>> {
    return apiInstance.post('/auth/login', { phone, password });
  }

  static async step2(
    code: string,
    secret: string
  ): Promise<AxiosResponse<ISignInStep2>> {
    return apiInstance.post('/auth/login/pinenter', { code, secret });
  }
}
