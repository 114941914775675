import React, { useState } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Alert } from '@mui/material';
import { Form } from '../UI/Form';
import { MyTimer } from '../UI/Timer';
import { FormBottomBlock } from '../UI/FormBottomBlock';
import LoadingButton from '@mui/lab/LoadingButton';

interface Props {
  submitAction: (value: string) => void;
  resendSmsAction: () => void;
  errorMessage: string;
  isLoading: boolean;
  timeNewPin: string;
  timer: boolean;
  stopTimer: () => void;
}

export const FormSmsCode = ({
  submitAction,
  errorMessage,
  isLoading,
  resendSmsAction,
  timeNewPin,
  timer,
  stopTimer
}: Props) => {
  const [code, setCode] = useState('');

  const handleChangePin = (newValue: string) => {
    setCode(newValue);
  };

  const handleResendSms = () => {
    resendSmsAction();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    submitAction(code);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <MuiOtpInput autoFocus value={code} onChange={handleChangePin} />
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <LoadingButton
        variant="contained"
        type="submit"
        loading={isLoading}
        disabled={code.length < 4}
      >
        <span>Продолжить</span>
      </LoadingButton>
      <FormBottomBlock>
        <LoadingButton
          variant="outlined"
          color="secondary"
          loading={isLoading}
          onClick={handleResendSms}
          disabled={timer}
          fullWidth
        >
          <span>Выслать код повторно</span>
        </LoadingButton>
        {timer && (
          <div>
            Отправить код повторно через:{' '}
            <MyTimer expiryTimestamp={timeNewPin} onExpire={stopTimer} />
          </div>
        )}
      </FormBottomBlock>
    </Form>
  );
};
