import React from 'react';
import { useUserStore } from '../../app/store';
import { Button } from '@mui/material';
import { ModalTitle } from '../UI/ModalTitle';
import logoutIcon from '../../assets/images/state-icon7.png';
import cls from './ModalLogout.module.scss';

interface Props {
  handleClose: () => void;
}

export const ModalLogout = ({ handleClose }: Props) => {
  const deleteUser = useUserStore((state) => state.deleteUser);

  const handleLogout = () => {
    deleteUser();
  };

  return (
    <div className={cls.container}>
      <div className={cls.forgot}>
        <ModalTitle
          icon={logoutIcon}
          title="Выход"
          text="Вы действительно хотите выйти из системы USDTCARD?"
        />
        <div className={cls.buttons}>
          <Button fullWidth color="secondary" onClick={handleClose}>
            Отмена
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={handleLogout}
          >
            Продолжить
          </Button>
        </div>
      </div>
    </div>
  );
};
