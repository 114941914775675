export const Paths = {
  APP: '/',
  SIGN_IN: '/sign-in',
  SIGN_IN_STEP_2: '/sign-in/code',
  SIGN_UP: '/sign-up',
  SIGN_UP_STEP_2: '/sign-up/code',
  SIGN_UP_STEP_3: '/sign-up/password',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_STEP_2: '/reset-password/code',
  RESET_PASSWORD_STEP_3: '/reset-password/password',
  MAIN: 'main',
  HISTORY: 'history',
  API: 'api',
  REGISTRY: 'registry',
  PAN: 'pan',
  DASHBOARD: 'dashboard',
  USERS: 'users',
  PROFIT: 'profit',
  NEWSLETTER: 'newsletter',
  SETTINGS: 'settings',
  SUPPORT: 'support',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_PASSWORD_STEP_3: '/settings/change-password/password',
  DELETE_ACCOUNT: 'delete-account',
  DELETE_ACCOUNT_CONFIRM: 'confirm',
  SMS_CODE: 'code',
  PASSWORD: 'password',
  DONE: 'done',
  DELETED: 'deleted',
  NOT_FOUND: '*'
} as const;
