import React from 'react';
import { ReactComponent as IconLogoHome } from '../../assets/icons/logoHome.svg';
import cls from './HomePage.module.scss';

export const HomePage = () => {
  return (
    <div className={cls.container}>
      <IconLogoHome />
      <h1>Добро пожаловать в PAYOUT</h1>
    </div>
  );
};
