import React from 'react';
import { useResetPasswordStore } from '../../app/store';
import { FormTitle } from '../../components/UI/FormTitle';
import { FormPhoneNumber } from '../../components/FormPhoneNumber';

export const Step1 = () => {
  const [step1, errorMessage, isLoading] = useResetPasswordStore((state) => [
    state.step1,
    state.errorMessage,
    state.isLoading
  ]);

  return (
    <>
      <FormTitle
        title={'Сброс пароля'}
        text={'Сбросьте пароль и задайте новый'}
      />
      <FormPhoneNumber
        action={step1}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </>
  );
};
