import React from 'react';
import { WidthLimiterPadding } from '../UI/WidthLimiter';
import cls from './Footer.module.scss';
import { useUserStore } from '../../app/store';

export const Footer = () => {
  const user = useUserStore((state) => state.user);

  return (
    <footer>
      <WidthLimiterPadding>
        <div
          className={cls.container}
          style={user ? { justifyContent: 'flex-start' } : {}}
        >
          <p>© 2024 PAYOUT.KG</p>
        </div>
      </WidthLimiterPadding>
    </footer>
  );
};
