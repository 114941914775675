import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../../app/configs/routePaths';
import { ModalTitle } from '../UI/ModalTitle';
import { Button } from '@mui/material';
import passIcon from '../../assets/images/state-icon4.png';
import cls from './ModalForgotPass.module.scss';

interface Props {
  handleClose: () => void;
}

export const ModalForgotPass = ({ handleClose }: Props) => {
  const navigate = useNavigate();

  return (
    <div className={cls.forgot}>
      <ModalTitle
        icon={passIcon}
        title="Забыли пароль?"
        text="Если вы забыли пароль, сбросьте его и пройдите повторный вход в
              систему."
      />
      <div className={cls.buttons}>
        <Button fullWidth color={'secondary'} onClick={handleClose}>
          Отмена
        </Button>
        <Button fullWidth onClick={() => navigate(Paths.RESET_PASSWORD)}>
          Продолжить
        </Button>
      </div>
    </div>
  );
};
