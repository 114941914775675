import { apiInstance } from '../api';
import { AxiosResponse } from 'axios';

export default class DeleteAccService {
  static async step1(uuid: string): Promise<AxiosResponse> {
    return apiInstance.post(`/auth/delete/${uuid}/pincreate`, {
      user_id: uuid
    });
  }

  static async step2(
    uuid: string,
    code: string,
    secret: string
  ): Promise<AxiosResponse> {
    return apiInstance.post(`/auth/delete/${uuid}/pinenter`, { code, secret });
  }
}
