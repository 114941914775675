import { RouteObject } from 'react-router-dom';
import { useUserStore } from '../../store';
import { UserRoles } from '../../models/userRoles';
import { Paths } from '../../configs/routePaths';
import {
  ApiPage,
  DashboardPage,
  DeleteAccPage,
  HistoryPage,
  MainOverview,
  NewsletterPage,
  PanPage,
  ChangePasswordPage,
  ProfitPage,
  ReestrPage,
  SettingsPage,
  UsersPage,
  DeleteAccStep1,
  DeleteAccStep2,
  HomePage,
  ChangePassStep1,
  ChangePassStep2,
  ChangePassStep3,
  ChangePassStep4
} from '../../../pages';
import { DeleteAccStep3 } from '../../../pages/DeleteAccPage';
import { SupportPage } from '../../../pages/SupportPage';

const settingsRoute: RouteObject = {
  path: Paths.SETTINGS,
  element: <SettingsPage />,
  children: [
    {
      path: Paths.CHANGE_PASSWORD,
      element: <ChangePasswordPage />,
      children: [
        {
          index: true,
          element: <ChangePassStep1 />
        },
        {
          path: Paths.SMS_CODE,
          element: <ChangePassStep2 />
        },
        {
          path: Paths.PASSWORD,
          element: <ChangePassStep3 />
        },
        {
          path: Paths.DONE,
          element: <ChangePassStep4 />
        }
      ]
    },
    {
      path: Paths.DELETE_ACCOUNT,
      element: <DeleteAccPage />,
      children: [
        {
          index: true,
          element: <DeleteAccStep1 />
        },
        {
          path: Paths.DELETE_ACCOUNT_CONFIRM,
          element: <DeleteAccStep2 />
        },
        {
          path: Paths.SMS_CODE,
          element: <DeleteAccStep3 />
        }
      ]
    },
    {
      path: Paths.SUPPORT,
      element: <SupportPage />
    }
  ]
};

export const userRoutes: RouteObject[] = [
  {
    path: Paths.APP,
    element: <MainOverview />
  },
  {
    path: Paths.HISTORY,
    element: <HistoryPage />
  },
  settingsRoute
];

export const userProRoutes: RouteObject[] = [
  {
    path: Paths.APP,
    element: <MainOverview />
  },
  {
    path: Paths.HISTORY,
    element: <HistoryPage />
  },
  /*  {
    path: Paths.API,
    element: <ApiPage />
  },*/
  {
    path: Paths.REGISTRY,
    element: <ReestrPage />
  },
  {
    path: Paths.PAN,
    element: <PanPage />
  },
  settingsRoute
];

export const adminRoutes: RouteObject[] = [
  {
    index: true,
    element: <HomePage />
  },
  {
    path: Paths.DASHBOARD,
    element: <DashboardPage />
  },
  {
    path: Paths.USERS,
    element: <UsersPage />
  },
  {
    path: Paths.PROFIT,
    element: <ProfitPage />
  },
  {
    path: Paths.NEWSLETTER,
    element: <NewsletterPage />
  },
  settingsRoute
];

const allUserRoutes = new Map([
  [UserRoles.USER, userRoutes],
  [UserRoles.PRO, userProRoutes],
  [UserRoles.ADMIN, adminRoutes]
]);

export const switchUserRoutes = () => {
  const userRole = useUserStore.getState().userRole;

  return allUserRoutes.get(userRole);
};
