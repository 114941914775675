import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AppState {
  isMobileWidth: boolean;
  setMobileWidth: (value: boolean) => void;
  isSnackbarOpen: boolean;
  setSnackbarOpen: (value: boolean) => void;
}

export const useAppStore = create<AppState>()(
  devtools(
    (set) => ({
      isMobileWidth: false,
      isSnackbarOpen: false,
      setMobileWidth: (value) => set({ isMobileWidth: value }),
      setSnackbarOpen: (value) => set({ isSnackbarOpen: value })
    }),
    { name: 'appStore' }
  )
);
