import React, { ReactNode } from 'react';
import cls from './FormBottomBlock.module.scss';

interface Props {
  children: ReactNode;
}

export const FormBottomBlock = ({ children }: Props) => {
  return <div className={cls.forgot}>{children}</div>;
};
