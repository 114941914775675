import cls from './ModalTitle.module.scss';

interface Props {
  icon: string;
  title?: string;
  text?: string;
  textBold?: string;
}

export const ModalTitle = ({ icon, title, text, textBold }: Props) => {
  return (
    <div className={cls.container}>
      <img className={cls.icon} src={icon} alt="state-icon" />
      <div className={cls.content}>
        {title && <h4>{title}</h4>}
        {text && <p className={cls.text}>{text}</p>}
        {textBold && <p className={cls.textBold}>{textBold}</p>}
      </div>
    </div>
  );
};
