import { createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { ruRU } from '@mui/x-date-pickers/locales';

declare module '@mui/material/styles' {
  interface Palette {
    white: Palette['primary'];
  }

  interface PaletteOptions {
    white?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}
declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    white: true;
  }
}

export const theme = createTheme(
  {
    typography: {
      fontFamily: 'Inter'
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#FF4000',
        dark: '#EB3B00',
        light: '#FF5C33',
        contrastText: '#FFF'
      },
      secondary: {
        main: '#161514',
        dark: '#000',
        light: '#24201E',
        contrastText: '#FFF'
      },
      white: {
        main: '#FFF',
        contrastText: '#FFF'
      },
      error: {
        main: '#ED7802'
      },
      success: {
        main: '#31AC60'
      },
      info: {
        main: '#e8e8e8'
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFF'
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            height: '44px',
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              transition: 'all .2s',
              borderColor: '#FF5C33'
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              transition: 'all .3s',
              border: '1px solid rgba(255, 92, 51, 1)',
              boxShadow: '0px 0px 0px 3px rgba(255, 92, 51, 0.3)'
            }
          },
          notchedOutline: {
            borderColor: '#E8E8E8'
          },
          input: {
            padding: '10px 0 10px 12px'
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {}
        }
      },
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          /*disableElevation: true,*/
          size: 'medium'
        },
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'none',
            lineHeight: '20px',
            borderRadius: '8px',
            '&.active': {
              background: '#E8E8E8'
            }
          },
          sizeMedium: {
            padding: '12px 20px'
          },
          sizeSmall: {
            padding: '8px 16px'
          }
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: 'outlined',
          displayEmpty: true
        },
        styleOverrides: {
          root: {
            background: '#FFF',
            borderRadius: 8,
            height: 44
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {},
          label: {
            fontSize: '14px'
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            //padding: "0 9px 0 9px"
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {}
        }
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            borderRadius: 16,
            backgroundColor: '#FFF',
            boxShadow: '0px 12px 25px 0px rgba(20, 22, 21, 0.08)'
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {},
          head: {
            color: '#837F7F',
            textTransform: 'uppercase'
          }
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: {},
          switchBase: (state) => ({
            '&.Mui-checked': {
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: state.theme.palette.primary,
                opacity: 1
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.3
              }
            }
          })
        }
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            boxShadow: '0px 4px 6px 0px rgba(22, 21, 20, 0.07)'
          }
        }
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            borderRadius: 8
          },
          action: {
            //padding: 0
          }
        }
      },
      MuiPagination: {
        styleOverrides: {
          root: {
            backgroundColor: '#FFF',
            borderRadius: 16,
            padding: 4
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          root: {},
          paper: {
            borderRadius: '16px',
            margin: '10px'
          }
        }
      }
    }
  },
  ruRU
);
