import React, { useState } from 'react';
import { Form } from '../UI/Form';
import { InputPhoneNum } from '../UI/InputPhoneNum';
import { Alert, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Paths } from '../../app/configs/routePaths';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormBottomBlock } from '../UI/FormBottomBlock';
import { CustomAlert } from '../UI/CustomAlert';

interface Props {
  action: (value: string) => void;
  errorMessage: string;
  isLoading: boolean;
}

export const FormPhoneNumber = ({ action, errorMessage, isLoading }: Props) => {
  const [phone, setPhone] = useState('+');
  const [error, setError] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    action(phone);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputPhoneNum
        phone={phone}
        error={error}
        setPhone={setPhone}
        setError={setError}
      />
      {errorMessage && <CustomAlert>{errorMessage}</CustomAlert>}
      <LoadingButton
        type="submit"
        variant="contained"
        loading={isLoading}
        disabled={error}
      >
        <span>Продолжить</span>
      </LoadingButton>
      <FormBottomBlock>
        <div>или</div>
        <Button
          color="secondary"
          variant="outlined"
          component={Link}
          to={Paths.SIGN_IN}
        >
          Войдите
        </Button>
      </FormBottomBlock>
    </Form>
  );
};
