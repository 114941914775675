export enum UserRoles {
  USER = 'User',
  PRO = 'Pro', // 2 | 3
  ADMIN = 'Admin' // 4 | 5 | 6 | 7
}

export interface ISelectOption {
  label: string;
  value: string;
}

export type PayoutJwtType = {
  exp: number;
  groups: string;
  t: string;
  user_id: string;
};
