import { AxiosResponse } from 'axios';
import { apiInstance } from '../api';
import { ISignUpStep1, ISignUpStep2 } from '../models/response/signUpResponse';

export default class ChangePasswordService {
  static async step1(uuid: string): Promise<AxiosResponse<ISignUpStep1>> {
    return apiInstance.post(`/${uuid}/change-password/pincreate`, {});
  }

  static async step2(
    uuid: string,
    code: string,
    secret: string
  ): Promise<AxiosResponse<ISignUpStep2>> {
    return apiInstance.post(`/${uuid}/change-password/pinenter`, {
      code,
      secret
    });
  }

  static async step3(
    uuid: string,
    token: string,
    password: string,
    password_confirm: string
  ): Promise<AxiosResponse> {
    return apiInstance.post(`/${uuid}/change-password`, {
      token,
      password,
      password_confirm
    });
  }
}
